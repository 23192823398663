<template>
  <div>
    <v-card style="height: 100vh">
      
        <v-app-bar-title>PINA Notaufnahme</v-app-bar-title>

        <v-spacer></v-spacer>
      <v-sheet>

        <v-container>
          <EventsAlt />
        </v-container>
      </v-sheet>
    </v-card>
  </div>
</template>

<script>
import EventsAlt from "../components/EventsAlt.vue";

export default {
  components: {
    EventsAlt,
  },
  name: "Fortbildungen",
  data() {
    return {};
  },
};
</script>
<style lang="scss">
.slidecard {
  padding: 1rem;
  color: white;
  font-size: 1.2rem;
  margin: 1rem;
  height: 200px;
  width: 300px;
  background: blue;
}
</style>
