<template>
  <div class="newsbg component-bg honto-border">
    <v-row dense style="border-bottom: 1px solid #ebebeb70">
      <v-col cols="12" sm="12" class="d-flex justify-space-between">
        <div style="font-weight: 500; font-size: 1.1rem">
          Fortbildungen PINA
        </div>

        <v-chip
          to="/fortbildungen"
          small
          text-color="primary"
          color="primary lighten-3"
          text
          outlined
          style="text-transform:uppercase;"
        >
          <v-icon class="pr-1" x-small>mdi-call-made</v-icon>
          alle
        </v-chip>
      </v-col>
    </v-row>
    <v-row class="mt-3">
      <v-col v-for="(item, i) in items" :key="i" cols="12" sm="4">
        <v-card flat tile class="newstile pt-0 mt-2 news-card honto-border">
          <v-row>
            <v-col cols="12" sm="12">
              <v-toolbar
                height="36"
                style="background: linear-gradient(45deg, #ff6060, transparent)!important;color:white!important;"
              >
                <div color="white" style="position: relative">
                  <div
                    style="margin: 0 !important; margin-right: 0.5rem"
                    class="card-header-date"
                  >
                    <div class="card-header-date-day">{{ item.day }}</div>
                    <div class="card-header-date-month">{{ item.month }}</div>
                  </div>
                </div>
                <v-toolbar-title
                  style="
                    white-space: break-spaces;
                    overflow: auto;
                    font-weight: 700;
                    color: white;
                    letter-spacing: 0.04rem;
                    font-size: 0.85rem;
                  "
                >
                  <v-icon small class="pr-1" style="top:-1px;" color="white">
                    mdi-lightbulb
                  </v-icon>
                  SCHLAUE OLGA
                </v-toolbar-title>
              </v-toolbar>
            </v-col>
            <v-col
              style="padding-top: 0; margin: 1rem; margin-top: -.5rem!important"
            >
              <v-list-item two-line class="py-0 px-1">
                <v-list-item-content style="margin-bottom:-0.5rem!important;">
                  <v-list-item-title style="font-weight:500;font-size:1rem;">
                    {{ item.text }}
                  </v-list-item-title>
                  <v-list-item-subtitle>{{
                    item.speaker
                  }}</v-list-item-subtitle>
                  <v-divider class="my-2 mb-3"></v-divider>
                  <v-list-item-subtitle
                    ><v-icon class="pr-2" small>mdi-clock-outline</v-icon
                    >{{ item.time }}</v-list-item-subtitle
                  ><v-list-item-subtitle>
                    <v-icon class="pr-2" small>mdi-map-marker-outline </v-icon
                    >{{ item.place }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data: () => ({
    selectedItem: 0,
    items: [
      {
        text: "Akutes Abdomen",
        icon:
          "https://hontostorage.blob.core.windows.net/strapi-uploads/assets/pina4_46b1cfa7bd.jpg?updated_at=2022-05-12T21:21:52.943Z",
        speaker: "Friedrich Reichert",
        day: "12",
        month: "Mai",
        time: "Freitag, 14:45 Uhr",
        place: "Mittagsbesprechungsraum",
      },
      {
        text: "Neonatale Cholestase",
        icon:
          "https://hontostorage.blob.core.windows.net/strapi-uploads/assets/pina5_ea90dd7085.png?updated_at=2022-05-12T21:22:06.474Z",
        speaker: "Axel Enninger",
        day: "08",
        month: "Dez",
        time: "Freitag, 14:45 Uhr",
        place: "Röntgen-Demoraum",
      },
      {
        text: "Ärztliches Debriefing",
        icon:
          "https://hontostorage.blob.core.windows.net/strapi-uploads/assets/pina3_7c2445faeb.png?updated_at=2022-05-12T21:22:03.819Z",
        speaker: "Thomas Heigele",
        day: "6",
        month: "Aug",
        time: "Freitag, 14:45 Uhr",
        place: "Röntgen-Demoraum",
      },
      {
        text: "Neonatale Cholestase",
        icon:
          "https://hontostorage.blob.core.windows.net/strapi-uploads/assets/pina5_ea90dd7085.png?updated_at=2022-05-12T21:22:06.474Z",
        speaker: "Axel Enninger",
        day: "22",
        month: "Dez",
        time: "Freitag, 14:45 Uhr",
        place: "Eingriffsraum MC21",
      },
      {
        text: "Echokardiografie",
        icon:
          "https://hontostorage.blob.core.windows.net/strapi-uploads/assets/pina2_9d3d9f48ce.png?updated_at=2022-05-12T21:22:05.557Z",
        speaker: "Friedrich Reichert",
        day: "11",
        month: "Jul",
        time: "Freitag, 14:45 Uhr",
        place: "Eingriffsraum MC21",
      },
      {
        text: "Akutes Abdomen",
        icon:
          "https://hontostorage.blob.core.windows.net/strapi-uploads/assets/pina4_46b1cfa7bd.jpg?updated_at=2022-05-12T21:21:52.943Z",
        speaker: "Friedrich Reichert",
        day: "12",
        month: "Mai",
        time: "Freitag, 14:45 Uhr",
        place: "Eingriffsraum MC21",
      },
      {
        text: "Echokardiografie",
        icon:
          "https://hontostorage.blob.core.windows.net/strapi-uploads/assets/pina2_9d3d9f48ce.png?updated_at=2022-05-12T21:22:05.557Z",
        speaker: "Friedrich Reichert",
        day: "11",
        month: "Jul",
        time: "Freitag, 14:45 Uhr",
        place: "Eingriffsraum MC21",
      },
      {
        text: "Ärztliches Debriefing",
        icon:
          "https://hontostorage.blob.core.windows.net/strapi-uploads/assets/pina3_7c2445faeb.png?updated_at=2022-05-12T21:22:03.819Z",
        speaker: "Thomas Heigele",
        day: "6",
        month: "Aug",
        time: "Freitag, 14:45 Uhr",
        place: "Eingriffsraum MC21",
      },
    ],
  }),
};
</script>
<style lang="scss">
.newstile {
  background: inherit;
  cursor: pointer;
}
.newstile:hover {
  z-index: 100;
  background: #c8b2b2 !important;
  cursor: pointer;
}
.component-bg {
  background: white;
  padding: 2rem;
  margin: 0;
  cursor: pointer;
}
.card-header-date,
.card-body-header-category {
  text-transform: uppercase;
  background-color: #fff;
  color: #333;
  font-weight: bold;
  text-align: center;
  right: 0;
  bottom: 0;
}

.card-header-date {
  margin: 8px;
  /* border-radius: 50%; */
  font-weight: bold;
  padding: 4px 9px;
  line-height: 15px;
}

.card-header-date-day {
  font-size: 15px;
}

.card-header-date-month {
  font-size: 9px;
}
.news-card:hover {
  background-color: #fafafa;
}
</style>
