<template>
<v-container style="margin:0px!important;padding:0px!important;">
      <v-row style="height:100vh;margin:0!important;padding:0!important;">
        <v-col class="loginarea" style="min-width:440px; border-right:1px solid #ccc;">
          <div class="bg"></div>
          <div class="mx-auto" style="margin-top:25%; max-width:500px;padding:2rem;">
            <div class="d-flex flex-row"><span><v-list-item-avatar
            
          >
            <img src="../img/logo-only.svg" />
          </v-list-item-avatar></span><span><v-list-item-title
            style="
              font-size: 1.5rem !important;
              margin-right: 32px;
              margin-top:0.75rem;
              margin-left: 0px;
              font-weight: 600;
              margin-bottom:2rem;
            "
            >HONTO</v-list-item-title
          ></span><v-divider class="mr-8" vertical style="height:60px;"></v-divider><span style="margin-top:0.45rem;"><span 
              ><span class="lstight" style="font-weight: 600">Klinikum Stuttgart</span
              ><br /><span class="lstight"
                >Olgahospital</span
              ></span
            ></span></div>

                <p
                  class="pb-0 pt-4 text--primary lstight"
                  style="
                    opacity:.6;
                    font-size: 1rem !important;
                    margin-bottom:-2rem;
                    background:none;
                  "
                >
                  Drücken Sie <span style="position:relative; top:-2px; box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25), 0 1px 2px -1px rgba(0, 0, 0, .4);
; margin:0 4px; font-size:.7rem;border-radius:3px;border:.5px solid #666;padding: .2rem .5rem;margin-bottom:5px;">Tab</span> zur schnellen Eingabe
                </p>

              <v-form class="mt-4">
                <v-text-field
                  v-model="username"
                  filled
                  append-icon="mdi-account"
                  name="login"
                  label="Benutzername"
                  type="text"
                  @keyup.enter="loginUser"
                ></v-text-field>
                <v-text-field
                  v-model="password"
                  filled
                  style="border-radius: 0px"
                  id="password"
                  name="password"
                  label="Passwort"
                  :type="passwordShown ? 'text' : 'password'"
                  @keyup.enter="loginUser"
                >
                  <template v-slot:append>
                    <v-icon
                      class="mr-2"
                      @click="passwordShown = !passwordShown"
                      >{{
                        passwordShown
                          ? "mdi-eye-off-outline"
                          : "mdi-eye-outline"
                      }}</v-icon
                    >
                    <v-icon @click="passwordShown = !passwordShown"
                      >mdi-lock</v-icon
                    >
                  </template>
                </v-text-field>
                <v-chip
                  v-if="invalidCredentials"
                  label
                  style="
                    margin-top: -1.5rem !important;
                    background: none !important;
                    color: tomato;
                  "
                  class="ma-0 errormsg swing-in-top-fwd"
                >
                  <v-icon style="color: tomato" left> mdi-alert </v-icon>
                  Sie haben ungültige Login-Daten eingegeben</v-chip
                >
              </v-form>
                <v-btn
                  tile
                  class="mb-1 px-8 py-6 pr-9"
                  style="color: white !important; box-shadow: none !important; border-radius:3px;"
                  color="black"
                  @click="loginUser"
                > 
                <v-icon class="nav-honto login-icon"></v-icon>

                  Login
                </v-btn></div>
</v-col><v-col class="blended"></v-col></v-row></v-container>
</template>

<script>
import Auth from "../utils/Auth";
export default {
  name: "login",
  data: () => ({
    username: null,
    password: null,
    passwordShown: false,
    invalidCredentials: false,
  }),
  async created() {
    if (Auth.get().authed) {
      this.$router.push("./");
    }
  },
  methods: {
    async loginUser() {
      await Auth.get().login(this.username, this.password);
      this.$router.push("./");
      }
  },

};
</script>

<style>
.blended {
  background-image: url("https://www.klinikum-stuttgart.de/fileadmin/mediapool/downloads/Presse/Presse_2022/Anaesthesie_2022_Ratermann_Focus.jpg");
  background-blend-mode: multiply;
  background-size: cover;
  background-position-x: 90%;
}
.loginarea {
    position: relative;
    z-index: 1;
}

.loginarea .bg {
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: url("https://keynote.tailwindui.com/_next/static/media/background.6c3571e0.jpg");
    opacity: .5;
    background-blend-mode: multiply;
    background-size:cover;
    background-position-y: 60%;
}

.errormsg {
  color: tomato;
}
@-webkit-keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
    transform: rotateX(-100deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 1;
  }
}
@keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
    transform: rotateX(-100deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 1;
  }
}
.swing-in-top-fwd {
  -webkit-animation: swing-in-top-fwd 1.5s
    cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
  animation: swing-in-top-fwd 1.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
}
.glass {
background: rgba( 255, 255, 255, 0.1 );
box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
backdrop-filter: blur( 4px );
-webkit-backdrop-filter: blur( 4px );
border-radius: 10px;
border: 1px solid rgba( 255, 255, 255, 0.18 );
}
.login-icon::before {
  content: " ";
  background-image: url("../img/play.svg");
  background-size: 22px 22px;
  height: 22px;
  width: 22px;
  position: relative;
  left: -0.5rem;
  display: inline-block;
  vertical-align: middle;
  font-size: 1em;
  -webkit-transition: -webkit-transform 0.4s;
  transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
  transition: transform 0.4s, -webkit-transform 0.4s;
  filter: brightness(0) invert(1);
}
</style>